html {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding-top: 90px;
  margin-bottom: 60px;
  /* Margin bottom by footer height */
  font-family: 'Libre Baskerville', serif;
  background-color: #eaf1f8;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  outline: none;
}

img {
  width: 100%;
}

.navbar-brand {
  font-size: 1.8rem;
}

.logo_pic {
  background: url('./img/logo_header.png') no-repeat;
  background-position: center;
  background-size: cover;
  height: 20vh;
  max-width:100vw;
}

.history .card .card-body {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 1.2rem;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  /* Set the fixed height of the footer here */
  line-height: 60px;
  /* Vertically center the text there */
  background-color: #f5f5f5;
}

.video-list {
  border: solid rgb(0, 0, 0) 2px;
  border-radius: .5rem;
  background-color: #ffffff;
  margin-bottom: .5rem;
}